import '../styles/nfts.css'
import React from 'react';
import { Link } from "react-router-dom";
import ssc1 from '../images/ls1.png';
import ssc2 from '../images/ls2.png';
import ssc3 from '../images/ls3.png';
import ssc4 from '../images/ls4.png';
import ssc5 from '../images/ls9.png';

function NFTs2() {
    return <div className="nft-container">


        <div className='summoner-images'>
        <img className= "flier" src={ssc1} />
        <img className= "flier" src={ssc2} />
        <img className= "flier" src={ssc3} />
        <img className= "flier" src={ssc4} />
        <img className= "flier" src={ssc5} />
        </div>

    </div>
}

export default NFTs2