import React from 'react'
import '../styles/web3.css';
import { Link } from "react-router-dom";

function web3() {
    return (
        <div className="web3-container">
            <div className="web3-content">
                <div className="web3-card">
                    <div className="card-info">
                        <h1>What is Web3?</h1>
                        <p>Web3 is a new way of interacting with the internet where creators and participants own their ideas and data. People connect to applications with a wallet (browser extension) instead of logging in with an email.</p>
                    </div>
                    <div className="card-link">
                        <a href="https://wallet.avax.network"><p>Learn more about Avalanche</p></a>
                    </div>
                </div>

                <div className="web3-card">
                    <div className="card-info">
                        <h1>What is an NFT?</h1>
                        <p>NFT stands for Non-Fungible Token. These tokens represent proof of ownership, providing a new way to share and trade art; even if it exists in a purely digital format.</p>
                    </div>
                    <div className="card-link">
                        <a href="https://ethereum.org/en/nft/"><p>Learn more about NFTs</p></a>
                    </div>
                </div>


                <div className="web3-card">
                    <div className="card-info">
                        <h1>What is Minting?</h1>
                        <p>Minting a Lady Surveyor grants you access to real time market calls while connecting you with a group of like-minded people.</p>
                    </div>
                    <div className="card-link">
                        <a href="https://avaxnfts.com"><p>Learn about AVAX NFTs</p></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default web3