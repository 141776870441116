import './App.css';
import Nav from "./components/nav";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from "./pages/home.js";
import NA from "./pages/NA";

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  midnightTheme,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';

import { publicProvider } from 'wagmi/providers/public';

// avax chain
const avaxChain = {
  id      : 43114,
  name    : 'Avalanche',
  network : 'Avalanche',

  nativeCurrency : {
    name     : 'Avalanche',
    symbol   : 'AVAX',
    decimals : 18,
  },
  rpcUrls : {
    default : 'https://api.avax.network/ext/bc/C/rpc',
  },
  blockExplorers : {
    default : {
      url  : 'https://snowtrace.io/',
      name : 'Snowtrace',
    },
  },
  testnet : false,
}

//create provider
const { chains, provider } = configureChains(
  [
    avaxChain
  ],
  [
    publicProvider(),
  ]
);

//connectors
const { connectors } = getDefaultWallets({
  chains,
  appName: 'sacrifice'
});

//create client
const wagmiClient = createClient({
  provider,
  connectors,
  autoConnect: true,
});

function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={midnightTheme({
          accentColor: "red",
          accentColorForeground: "white",
          borderRadius: "small",
        })}
        modalSize="wide"
      >
        <Router>
          <Nav />
          <Routes>
            <Route exact path="/" element ={<Home/>} />
            <Route exact path="*" element ={<NA/>} />
          </Routes>
        </Router>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
