import React from 'react'
import '../styles/featured.css'
import { Link } from "react-router-dom";
import Flier from "../images/surv-logo.png";

function Featured() {
    return <div className="featured-container">
    <div className="featured-content">
    <img className= "flier" src={Flier} />
    </div>
    <div className="featured-content">
    <h1>Surveyor DAO</h1>
    <p>A group of seasoned investors coming together to share insight and tips. Your NFT will give you exclusive access to alpha, market opportunities and strategies.</p>
    <p>The Surveyor DAO was founded by our fearless leader, Shitcoin Surveyor, on his quest to harvest the highest yeilds across the land.</p>
    <a href="https://surveyor-dao.gitbook.io/surveyor-dao/"><button>Explore</button></a>
    </div>
    </div>
}

export default Featured