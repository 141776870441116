import '../styles/header.css'
import Header_IMG from "../images/PFP.png";
import React, {useState} from 'react';
import {useContractRead, usePrepareContractWrite, useContractWrite, useAccount } from 'wagmi';
import ladySurvABI from "../contractABIs/ladySurvABI.json";
import usdcABI from "../contractABIs/usdcABI.json";
import { TextField } from '@mui/material';
import { ethers } from 'ethers';
import MintButton from './MintButton';


function Header() {

    const [approveAmount, setApproveAmount] = useState("0");

    const { address } = useAccount();

    const ladyContract = {
        addressOrName: '0xB367360D9CCC6F5904ebD5784d7A848d653c01aA',
        contractInterface: ladySurvABI
    }

    const usdcContract = {
        addressOrName: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        contractInterface: usdcABI
    }

    const { data: currentSupply, isError, isLoading, supplyReadError } = useContractRead({
        ...ladyContract,
        functionName: 'totalSupply',
        onSuccess() {
            console.log(currentSupply.toString());
        },
        onError() {
            console.log("Read error", supplyReadError);
        }
    });
    
    const { data: currentApproved, approveReadError } = useContractRead({
        ...usdcContract,
        functionName: 'allowance',
        args: [address, '0xB367360D9CCC6F5904ebD5784d7A848d653c01aA'],
        onSuccess() {
            console.log(currentApproved.toString());
            setApproveAmount(currentApproved.toString());
        },
        onError() {
            console.log(approveReadError);
        }
    });

    const { config: approveConfig, approveWriteError } = usePrepareContractWrite({
        ...usdcContract,
        functionName: 'approve',
        args: ['0xb367360d9ccc6f5904ebd5784d7a848d653c01aa', ethers.constants.MaxUint256],
        onSuccess() {
            console.log(approveConfig);
            setApproveAmount(ethers.constants.MaxUint256);
        },
        onError() {
            console.log("Approve prep error: ", approveWriteError);
        }
    });

    const { write } = useContractWrite(approveConfig);

    return (
        <div className="header-container">
            <div className="header-content">
                <h3>LADY SURVEYORS</h3>
                <p>Super powered beings sent to survey and protect the ecosystem.</p>
                <h4>Join the Discord for updates</h4>
                
                <button><a href="https://discord.gg/q8ZcWD5h68" class="button">Join Us</a></button>
               
            </div>
            <div className="header-content">
                <img src={Header_IMG} />
            </div>
        </div>
    );
}

export default Header