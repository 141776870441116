import React from 'react';
import { ConnectButton } from "@rainbow-me/rainbowkit";
import '../styles/nav.css';
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";

function Nav() {
    return <div className="navbar">
    <div className="navbar-content">
        <div className = "left-nav">
        <img src={Logo} />
        </div>
        <div className = "right-nav">
            <div className ="nav-links">
                <a href="https://surveyor-dao.gitbook.io/surveyor-dao/">Docs</a>
                
            </div>
            <ConnectButton />
        </div>
    </div>
    </div>
}

export default Nav