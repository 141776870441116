import React from "react";
import '../styles/home.css';
import Header from "../components/header";
import Featured from "../components/featured";
import NFTs from "../components/nfts2";
import NFTs2 from "../components/nfts3";
import Footer from "../components/footer";

import Web3 from "../components/web3";

function Home() {
    return <div className="home-page">
        <Header />
        <Featured />
        <NFTs />
        <NFTs2 />
        <Web3 />
        <Footer />
    </div>
}

export default Home;