import '../styles/nfts.css'
import React from 'react';
import { Link } from "react-router-dom";
import ssc1 from '../images/ls5.png';
import ssc2 from '../images/ls6.png';
import ssc3 from '../images/ls7.png';
import ssc4 from '../images/ls8.png';
import ssc5 from '../images/ls10.png';

function NFTs3() {
    return <div className="nft-container">


        <div className='summoner-images'>
        <img className= "flier" src={ssc1} />
        <img className= "flier" src={ssc2} />
        <img className= "flier" src={ssc3} />
        <img className= "flier" src={ssc4} />
        <img className= "flier" src={ssc5} />
        </div>

    </div>
}

export default NFTs3